<style>
.v-text-field input {
  font-size: 0.75em;
}
</style>
<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-account</v-icon>
          USUARIOS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn small color="primary" dark @click="abrirDialogoCrear"
          ><v-icon>mdi-plus</v-icon>Crear usuario</v-btn
        >
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-text-field
            v-model="busqueda"
            @click:append="cargarLista()"
            @keyup.enter="cargarLista()"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            class="mt-5"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="busTipoUsuario"
            @change="cargarLista()"
            clearable
            label="Tipo de usuario"
            class="mt-5"
            :items="tiposUsuario"
            item-text="tipo_ente_desc"
            item-value="tipo_ente_id"
          >
          </v-select>
        </v-col>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="_headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="green"
            title="Permisos usuario"
            @click="abrirFormUsuarioUsuario(item)"
          >
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
          <v-btn
            icon
            color="green"
            title="Cambiar contraseña"
            @click="abrirFormUsuarioContrasena(item)"
          >
            <v-icon>mdi-account-key</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG CAMBIO CONTRASEÑA USUARIO -->
    <v-dialog v-model="dialogContrasenaUsuario" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-account-key</v-icon>
          <span class="headline">{{ formTitulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formContrasenaUsuario">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="duser.nombres"
                    label="Nombres"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="duser.userName"
                    label="Usuario"
                    :readonly="true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                    :rules="reglasPass"
                    v-model="duser.password"
                    label="Ingrese la nueva Contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    v-model="duser.password2"
                    :rules="reglasPass"
                    label="Repita la contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="cambiarContrasenaUsuario">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn
              small
              @click="dialogContrasenaUsuario = !dialogContrasenaUsuario"
            >
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG  USUARIO -->
    <v-dialog v-model="dialogUsuario" persistent max-width="750px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-account-edit</v-icon>
          <span class="headline">Datos Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-tabs vertical v-model="userTab">
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              Info General
            </v-tab>

            <v-tab>
              <v-icon left> mdi-account-settings </v-icon>
              Privilegios
            </v-tab>

            <v-tab-item default>
              <v-card-text flat class="pt-2">
                <v-container>
                  <v-form ref="formUsuario">
                    <v-row>
                      <v-col class="pt-0 pb-0" cols="12">
                        <v-text-field
                          v-model="duser.nombres"
                          label="Nombres"
                          :readonly="true"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="pt-0 pb-0" cols="12">
                        <v-text-field
                          v-model="duser.userName"
                          label="Usuario"
                          :readonly="true"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-text flat class="pt-2">
                <v-toolbar flat>
                  <v-autocomplete
                    v-model="privId"
                    small
                    item-text="PRIV_NOMBRE"
                    item-value="PRIVILEGIO_ID"
                    :items="listaPrivilegios"
                    clearable
                    label="Seleccione un dato"
                  >
                  </v-autocomplete>

                  <v-spacer></v-spacer>

                  <v-btn small color="info" @click="agregarPrivilegio">
                    <v-icon>mdi-plus</v-icon> Agregar privilegio
                  </v-btn>
                </v-toolbar>
                <v-data-table
                  :headers="headerPrivilegios"
                  :items="privilegiosUsuario"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  :loading="loadingTable"
                  class="elevation-1"
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      icon
                      color="red"
                      title="borrar"
                      @click="borrarPrivilegio(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              small
              color="primary"
              :disabled="lb"
              :loading="lb"
              @click="guardarUsuario"
            >
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn small @click="dialogUsuario = !dialogUsuario">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CREAR USUARIO -->
    <v-dialog v-model="dialogCrearUsuario" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-account</v-icon>
          <span class="headline">{{ formTitulo }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formCrearUsuario">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="datosNuevoUsuario.NOMBRES"
                    label="Nombres"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="datosNuevoUsuario.USER_NAME"
                    label="Usuario"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                    :rules="reglasPassNuevoUsuario"
                    v-model="datosNuevoUsuario.PASSWORD"
                    label="Ingrese la nueva Contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    prepend-icon="mdi-lock"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    v-model="datosNuevoUsuario.PASSWORD2"
                    :rules="reglasPassNuevoUsuario"
                    label="Repita la contraseña"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="crearUsuario">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;
            <v-btn small @click="dialogCrearUsuario = !dialogCrearUsuario">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ListaUsuariosComponent",
  props: {
    fincaId: {
      type: String,
      default: null,
    },
    tipoEmpresa: {
      type: String,
      default: null,
    },
  },
  components: {},
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Tipo id", value: "TIPO_ENTE_ID" },
      { text: "Tipo", value: "TIPO_ENTE_DESC" },
      { text: "Nombre", value: "ENTE_NOMBRE" },
      { text: "Usuario", value: "USER_NAME" },
    ],

    headerPrivilegios: [
      { text: "Opciones", value: "opcion" },
      { text: "Privilegios", value: "PRIV_NOMBRE" },
    ],

    tiposUsuario: [
      { tipo_ente_id: 1, tipo_ente_desc: "Finca" },
      { tipo_ente_id: 4, tipo_ente_desc: "Usuario" },
    ],

    lista: [],
    privilegiosUsuario: [],
    show: false,
    show2: false,
    options: {},
    notifications: false,
    dialogUsuario: false,
    dialogContrasenaUsuario: false,
    formTitulo: "Cambiar contraseña",
    duser: {
      userName: "",
      password: "",
      password2: "",
    },
    datosNuevoUsuario: {
      USER_NAME: "",
      PASSWORD: "",
      PASSWORD2: "",
      NOMBRES: "",
    },
    listaPrivilegios: [],
    privId: "",
    busqueda: null,
    busTipoUsuario: "",
    userTab: 0,
    itemsPerPage: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    lb: false,
    dialogCrearUsuario: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months", "loadingBtn"]),

    ...mapGetters("access", []),
    _headers() {
      let head2 = [];
      for (let i = 0; i < this.headers.length; i++) {
        head2.push(this.headers[i]);
      }

      return head2;
    },
    reglasPass() {
      const strongPattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.!@#\$%\^&\*])(?=.{5,})"
      );

      const mediumPpattern =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

      if (strongPattern.test(this.duser.password) == false) {
        return [
          false ||
            "La contraseña debe tener al menos 6 caractéres, al menos una letra mayúscula un número y un caractér especial",
        ];
      }

      if (this.duser.password != this.duser.password2) {
        return [false || "Las contraseñas no coinciden"];
      }
    },

    reglasPassNuevoUsuario() {
      const strongPattern = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.!@#\$%\^&\*])(?=.{5,})"
      );

      const mediumPpattern =
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;

      if (strongPattern.test(this.datosNuevoUsuario.PASSWORD) == false) {
        return [
          false ||
            "La contraseña debe tener al menos 6 caractéres, al menos una letra mayúscula un número y un caractér especial",
        ];
      }

      if (this.datosNuevoUsuario.PASSWORD != this.datosNuevoUsuario.PASSWORD2) {
        return [false || "Las contraseñas no coinciden"];
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-usuarios");

      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          busqueda: this.busqueda,
          busTipoUsuario: this.busTipoUsuario,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          this.listaPrivilegios = res.data.privilegios;
          //console.log(this.totalItems)
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirFormUsuarioContrasena(du) {
      this.duser.nombres = du.ENTE_NOMBRE;
      this.duser.userName = du.USER_NAME;
      this.duser.password = "";
      this.duser.password2 = "";
      this.dialogContrasenaUsuario = true;
      this.tab;
    },

    abrirFormUsuarioUsuario(du) {
      this.duser.nombres = du.ENTE_NOMBRE;
      this.duser.userName = du.USER_NAME;
      this.duser.password = "";
      this.duser.password2 = "";
      this.userTab = 0;

      this.lb = true;
      this.setUrl("api/privilegios-usuario");
      this.requestApi({
        method: "GET",
        data: {
          username: du.USER_NAME,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)
          this.lb = false;
          this.privilegiosUsuario = res.data.lista;
          this.dialogUsuario = true;
        })
        .then(() => {
          this.lb = false;
        });
    },

    cambiarContrasenaUsuario() {
      console.log(this.duser);
      if (!this.$refs.formContrasenaUsuario.validate()) {
        return;
      }
      this.setUrl("api/guardar-password");
      this.requestApi({
        method: "POST",
        data: {
          username: this.duser.userName,
          password: this.duser.password,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)

          this.dialogContrasenaUsuario = false;
        })
        .then(() => {});
    },

    guardarUsuario() {
      console.log(this.duser);
      if (!this.$refs.formUsuario.validate()) {
        return;
      }
      this.lb = true;
      this.setUrl("api/guardar-usuario");
      this.requestApi({
        method: "POST",
        data: {
          username: this.duser.userName,
          nombresUsuario: this.duser.nomresUsuario,
          ente_id: this.duser.ente_id,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)
          this.lb = false;
          this.dialogUsuario = false;
        })
        .then(() => {
          this.lb = false;
        });
    },

    agregarPrivilegio(du) {
      console.log(this.duser);

      this.lb = true;
      this.setUrl("api/agregar-privilegio");
      this.requestApi({
        method: "POST",
        data: {
          username: this.duser.userName,
          privilegio_id: this.privId,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)
          this.lb = false;
          this.cargarPrivilegiosUsuario(this.duser.userName);
        })
        .then(() => {
          this.lb = false;
        });
      this.dialogPermiso = false;
    },

    borrarPrivilegio(item) {
      this.setUrl("api/borrar-privilegio-usuario");
      this.requestApi({
        method: "POST",
        data: {
          username: item.USER_NAME,
          privilegio_id: item.PRIVILEGIO_ID,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)
          this.lb = false;
          this.cargarPrivilegiosUsuario(item.USER_NAME);
        })
        .then(() => {
          this.lb = false;
        });
    },

    cargarPrivilegiosUsuario(id) {
      this.setUrl("api/privilegios-usuario");
      this.requestApi({
        method: "GET",
        data: {
          username: id,
        },
      })
        .then((res) => {
          //console.log(res.data.dae)
          this.lb = false;
          this.privilegiosUsuario = res.data.lista;
        })
        .then(() => {
          this.lb = false;
        });
    },
    handlePageChange() {
      this.cargarLista();
    },

    abrirDialogoCrear() {
      this.datosNuevoUsuario = {
        NOMBRES: "",
        USER_NAME: "",
        PASSWORD: "",
        PASSWORD2: "",
      };
      this.formTitulo = "Crear usuario";
      this.dialogCrearUsuario = true;
    },

    crearUsuario() {
      this.setUrl("api/crear-usuario");
      this.requestApi({
        method: "POST",
        data: {
          datos_usuario: this.datosNuevoUsuario,
        },
      })
        .then((res) => {
          this.dialogCrearUsuario = false;
          console.log(res.data);
          if (res.data.success == true) {
            this.abrirFormUsuarioUsuario({
              ENTE_NOMBRE: this.datosNuevoUsuario.NOMBRES,
              USER_NAME: this.datosNuevoUsuario.USER_NAME,
            });
          }
        })
        .then(() => {
          this.lb = false;
        });
    },
  },
  mounted() {
    //this.$vuetify.lang.current = 'es'
    //this.cargarLista()
    document.title = "Sistema de carga";
    this.setTitleToolbar("Usuarios");
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
